.hero-container{
    justify-content: space-between;
    align-items: flex-end;
    background: var(--black);
  }
.hero-des{
    padding: 20px;
}
.hero-des>p{
    font-size: 0.9rem;
}
.hero-wrapper{
    color: white;
    position: relative;
    padding-bottom: 2rem;
    z-index: 1;
 
  }
.hero-left{
    position: relative;
    gap: -200rem;
}

.hero-title{
    position: relative;
    z-index: 1;
    margin-top: 35px;
}
.hero-title>h1{
    font-weight: 600;
    font-size:3.4rem;
    line-height: 4rem;
}
  .primaryText{
    color: #1f3e72;
    font-weight: bold;
    font-size: 2rem;
  }
  .secondaryText{
    color: rgb(140 139 139);
    font-size: 0.9rem;
  }
  .orangeText{
    color: orange;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .search-bar{
    background-color: white;
    border-radius: 5px;
    border: 3px solid rgba(120, 120, 120, 0.374);
    padding: 5px;
    justify-content: space-between;
    margin-top: 15px;
  }
  .search-bar>input{
    border: none;
    outline: none;
  }
  .orange-circle{
    height: 3rem;
    width: 3rem;
    background: var(--orange-gradient);
    border-radius: 999px;
    position: absolute;
    right: 18%;
    top: -8%;
    z-index:-1 ;
  }
.image-container{
    margin: auto;
    width: 100%;
    max-width: 22rem;   
    height: 100%;
    max-height: 25rem;
    overflow: hidden;
    border-radius: 15rem 15rem 0 0 ;
    border: 8px solid rgba(255, 255, 255, 0.12);
}
.image-container>img{
     width: 100%;
     height: 100%;
}
@media(max-width:768px){
  .hero-title>h1{
    font-size: 2.5rem;
    line-height: 3rem;
  }
  .image-container{
    width: 95%;
    height: 30rem;
  } 
  .hero-right{
     width: 100%;
  }
}
